/* eslint-disable react/no-danger */
import React from 'react';
import { Link, graphql, withPrefix } from 'gatsby';
import PageLayout from '@global/page-layout';
import ReadLink from '@components/ReadLink/ReadLink';
import VerticalsHero from '@containers/VerticalsHero';
import VerticalsFeatures from '@containers/VerticalsFeatures';
import VerticalsCTA from '@containers/VerticalsCTA';
import VerticalsPain from '@containers/VerticalsPain';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import readingTime from 'reading-time';
import {
  ImageContainer,
  TagContainer,
  Tag,
  PostHeaderRow,
  PostDate,
  ArticleLink,
  ArticleFooterList,
  Body,
} from './industry.styled';

export const query = graphql`
  query($slug: String!) {
    fullIndustriesSectionJson(
      highlightedIndustries: { elemMatch: { slug: { eq: $slug } } }
    ) {
      highlightedIndustries {
        slug
        headline
        title
        tag
        image
        pain
        subTitle
        description
        valueProps {
          headerText
          title
          subTitle
          benefits {
            title
            description
            video
          }
        }
      }
    }
  }
`;

const IndustryTemplate = ({
  data: { fullIndustriesSectionJson: industries },
  pageContext,
  location,
}) => {
  const { highlightedIndustries } = industries;
  const industry = highlightedIndustries?.find(
    (ind) => ind.slug === pageContext.slug
  );

  const {
    title,
    headline,
    description,
    image,
    pain,
    valueProps,
    subTitle,
  } = industry;
  const { previous, next } = pageContext;

  return (
    <PageLayout
      title={title}
      description={description.description}
      location={location}
    >
      <VerticalsHero
        title={title}
        headline={headline}
        image={image}
        subTitle={subTitle}
        location={location}
      />
      <VerticalsPain pain={pain} />
      <VerticalsFeatures valueProps={valueProps[0]} />
      <VerticalsCTA location={location} />
    </PageLayout>
  );
};

IndustryTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

export default IndustryTemplate;
