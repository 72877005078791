import React from 'react';
import { popup } from '@src/global/utils/typeformPopup';
import CTA2 from '@containers/CTA2';
import PropTypes from 'prop-types';

function VerticalsCTA({ location }) {
  let ctaLink;

  if (location && location?.pathname === '/industries/swimming-school') {
    ctaLink = 'https://picortex.typeform.com/to/tz9MhwnS';
  } else if (location && location?.pathname === '/industries/retail') {
    ctaLink = 'https://www.piretail.shop';
  } else {
    ctaLink = 'https://picortex.typeform.com/to/NBpm8D';
  }
  return (
    <CTA2
      onPress={() => popup(ctaLink)}
      title="Join the next generation of businesses using PiCortex to transform their business today!"
    />
  );
}
VerticalsCTA.propTypes = {
  location: PropTypes.object,
};

export default VerticalsCTA;
