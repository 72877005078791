import React from 'react';
import { withPrefix } from 'gatsby';
import PropTypes from 'prop-types';

function Video({ src }) {
  return (
    <video
      autoPlay
      muted
      loop
      preload="metadata"
      poster="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1599216634/PiCortex%20Website/404_gj4wl6.jpg"
      className="w-full object-cover object-center rounded-lg shadow-md"
      style={{
        // position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        // height: '100%',
        // width: '100%',
        zIndex: '-1',
        objectFit: 'fill',
        objectPosition: 'center',
      }}
      src={withPrefix(`${src}`)}
    >
      <source src={withPrefix(`${src}`)} type="video/mp4" />
      Your device does not support playing 'video/mp4' videos
    </video>
  );
}

Video.propTypes = {
  src: PropTypes.string.isRequired,
};
export default Video;
