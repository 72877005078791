import React, { useState } from 'react';
import {
  StyledContainer,
  StyledSection,
  Heading,
  SubHeading,
} from '@styled/components';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '@components/image';
import PropTypes from 'prop-types';
import ScheduleADemoButton from '@components/ScheduleADemoButton';
import Modal from '@utils/Modal';
import { ImageContainer, HeaderWrapper, FormContainer } from './hero.style';

const VerticalsHero = ({ title, headline, image, subTitle, location }) => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  let ytLink = 'https://www.youtube.com/embed/cCWJg8xOX5s';
  if (location && location.pathname === '/industries/swimming-school') {
    ytLink = 'https://www.youtube.com/embed/5vLy3o0FtZE';
  }
  return (
    <div className="relative bg-gray-200">
      <div className="relative bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="h-8 w-auto sm:h-10 flex justify-start lg:w-0 lg:flex-1"></div>
          </div>
        </div>
      </div>
      <main className="lg:relative">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h2 className="text-base font-semibold text-green-500 tracking-wide uppercase">
              {title}
            </h2>
            {/* <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block xl:inline">{headline}</span>
          </h1> */}
            <h1>
              <span className="mt-1 block text-4xl tracking-tight font-bold sm:text-5xl xl:text-6xl">
                <span className="block text-gray-900">{headline}</span>
              </span>
            </h1>
            <p className="mt-3 text-lg text-gray-600 leading-normal sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              {subTitle}
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <ScheduleADemoButton />
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <button
                  className="w-full inline-flex items-center justify-center px-3 py-3 text-base leading-6 font-medium group rounded-md text-gray-100 border-solid border-2 bg-primaryColor hover:border hover:border-white hover:bg-green-700 hover:text-gray-100 focus:outline-none transition duration-150 ease-in-out"
                  onClick={(e) => {
                    e.preventDefault();
                    setVideoModalOpen(true);
                  }}
                  aria-controls="modal"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-5 h-5 fill-current text-gray-200 group-hover:text-primaryColor flex-shrink-0"
                  >
                    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                    <path d="M10 17l6-5-6-5z" />
                  </svg>
                  <span className="ml-3">Watch our intro video</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={image}
            alt={title}
          />
        </div>
        <Modal
          id="modal"
          ariaLabel="modal-headline"
          show={videoModalOpen}
          handleClose={() => setVideoModalOpen(false)}
        >
          <div className="relative pb-9/16">
            <iframe
              height="600px"
              className="w-full"
              src={`${ytLink}`}
              title="PiCortex intro video"
              allowFullScreen
            ></iframe>
          </div>
        </Modal>
      </main>
    </div>
  );
};

VerticalsHero.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};
export default VerticalsHero;
