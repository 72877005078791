import React from 'react';
import PropTypes from 'prop-types';
import Video from '@components/Video';

function VerticalsFeatures({ valueProps }) {
  const { headerText, title, subTitle, benefits } = valueProps;
  return (
    <div className="bg-gray-200">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-green-500 uppercase tracking-wide">
            {headerText}
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">{title}</p>
          <p className="mt-4 text-xl text-gray-600 leading-relaxed">
            {subTitle}
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {benefits.map((benefit, id) => {
              const { video, title: benefitTitle, description } = benefit;
              return (
                <div key={id}>
                  {video ? <Video src={video} /> : null}
                  <div className="flex mt-3" key={id}>
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <div className="ml-3">
                      <dt className="text-xl leading-6 font-medium text-gray-900">
                        {benefitTitle}
                      </dt>
                      <dd className="mt-2 text-lg leading-relaxed text-gray-600">
                        {description}
                      </dd>
                    </div>
                  </div>
                </div>
              );
            })}
          </dl>
        </div>
      </div>
    </div>
  );
}

VerticalsFeatures.propTypes = {
  valueProps: PropTypes.object.isRequired,
};
export default VerticalsFeatures;
